:root {
  --primary-color: #3facd7;
}

.btn {
  padding: 0.6rem 0.8rem;
  font-size: 1.2rem;
  text-align: center;
  border: 0;
  outline: none;
  border-radius: 5px;
  width: 120px;
  /* margin-left: 10px; */
  /* box-shadow: 1px -3px 52px -5px rgba(0, 0, 0, 0.77); */
  cursor: pointer;
}

.btn.add {
  background-color: var(--primary-color);
}

.btn.remove {
  background-color: tomato;
}

.btn.checkout {
  background-color: rgb(38, 121, 38);
  width: 150px;
  color: #fff;
  font-size: 14px;
}

.btn:active {
  transform: scale(0.98);
}

.btn.add:hover {
  background-color: #cab320;
}

.btn.add:active {
  background-color: #ad9a1c;
}

.btn.remove:hover {
  background-color: rgb(209, 83, 61);
}
.btn.remove:active {
  background-color: rgb(185, 73, 54);
}

.btn.checkout:hover {
  background-color: rgb(41, 141, 41);
}
.btn.checkout:active {
  background-color: rgb(30, 93, 41);
}
